export default {
  computed: {
  },
  methods: {
    async getAudioDurationFromBase64OrUrl(url) {
      return this.$utils.getAudioDurationFromBase64OrUrl(url);
    },
    async getAudioDurationFromFile(file) {
      return this.$utils.getAudioDurationFromFile(file);
    },
  },
  filters: {}
}